.bg-weather {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(90, 110, 221, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

.wrap-weather-hourly,
.wrap-weather-daily {
  display: flex;
  max-width: 100%;
  overflow: auto;
}
.weather-hourly {
  margin: 5px;
  text-align: center;
  padding: 5px;
  width: 200px;
  min-width: 200px;
  max-width: 100%;
  border-radius: 5px;
  background: #ffffff26;
}

.wrap-search-bar {
  width: 90%;
  position: relative;
  max-width: 500px;
}

.filter-text{
  background: #ffffffcc;
  width: 100%;
  padding: 10px 15px;
  font-size: 1.15em;
  border: none;
  border-radius: 5px;
}

.show-autocomplete .filter-text{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-text:focus{
  outline: none;
  background: #fff;
}

.autocomplete li a {
  width: 100%;
  padding: 5px 15px;
  border-bottom: 1px solid #cecccc;;
}

.autocomplete {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 9999;
  position: absolute;
  width: 100%;
  align-self: center;
}

.autocomplete li a {
  width: 100%;
  color: #222;
  background: #e1e1e1;
  display: inline-block;
  text-decoration: none;
}

.autocomplete li a:hover,
.autocomplete li a:focus,
.autocomplete li a:active {
  background: #f1f1f1;
  text-decoration: underline;
}

.forecast {
  color: #fff;
  font-size: 1.3rem;
  margin-left: 5px;
}
.weather-hourly p,
.current-date {
  color: #ffffff;
}

.main-distance-bottom {
  margin-bottom: 10vh;
}

.flex-height {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
}

.currentIcon {
  width: 100px;
}

.current-weather {
  display: flex;
}

.current-weather-info {
  color: #fff;
}

.current-weather-info .current-temp {
  font-size: 50px;
}

.scroll-style::-webkit-scrollbar {
  height: 10px;
}

.scroll-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.scroll-style::-webkit-scrollbar-thumb {
  background: #ffffff26;
  outline: 1px solid slategrey;
}

.heightItem {
  min-height: 25vh;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.self-center {
  align-self: center;
}

.heightSearchBar {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}
